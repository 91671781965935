'use client'

import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { useCallback, useContext, useEffect, useState } from 'react'

import type { ReactElement } from 'react'

import Button from '~/components/Button'
import TopNavbar from '~/components/TopNavbar'
import { UserContext } from '~/contexts/UserContext'

import './styles.scss'

const Home = (): ReactElement => {
  const router = useRouter()

  const [hidden, setHidden] = useState(false)

  const { account } = useContext(UserContext)

  const handleRedirect = () => (account ? router.push('/account') : router.push('/authentication?source=sign_in'))

  const [scrollingValue, setScrollingValue] = useState(0)

  const controlNavBarDisplay = useCallback(() => {
    if (window.scrollY - scrollingValue > 20) {
      setHidden(true)
      setScrollingValue(window.scrollY)
    } else if (window.scrollY - scrollingValue < -20) {
      setHidden(false)
      setScrollingValue(window.scrollY)
    }
  }, [scrollingValue])

  useEffect(() => {
    window.addEventListener('scroll', controlNavBarDisplay)
    return () => window.removeEventListener('scroll', controlNavBarDisplay)
  }, [controlNavBarDisplay])

  return (
    <div className="home-view">
      <TopNavbar hidden={hidden} />
      <div className="main-section">
        <div className="image-container">
          <Image alt="Stemz logo" className="background-image" fill src="/medias/vinyl_de_fou-min.png" />{' '}
        </div>
        <div className="main-section__content">
          <div>
            <h2 className="title">
              Embrace the power of <span className="text-gradient">AI today</span>
            </h2>
            <div className="subtitle">State-of-the-art AI modules for your business</div>
          </div>
          <div className="button-wrapper-container">
            <div className="try-stemz-web-button-container">
              <Button className="try-stemz-web-button-container__button" onClick={handleRedirect}>
                <span className="label-main-text">Get started</span>
                <Image
                  alt="Arrow icon"
                  className="arrow-icon"
                  height={42}
                  src="/medias/arrow-right-white-big.png"
                  width={42}
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-want-to-try">
        <div className="section-want-to-try__content">
          <div className="section-want-to-try__content__title">Ready to take your project to the next level?</div>
          <div className="section-want-to-try__content__subtitle">
            Get started now or reach out for further assistance and information.
          </div>
          <div className="try-stemz-web-button-container">
            <Button className="try-stemz-web-button-container__button" onClick={handleRedirect}>
              <span className="label-main-text">Get started</span>
              <Image
                alt="Arrow icon"
                className="arrow-icon"
                height={42}
                src="/medias/arrow-right-white-big.png"
                width={42}
              />
            </Button>
          </div>
        </div>
      </div>
      <div className="section-legal">
        <div className="license">© 2023 MWM. All rights reserved.</div>
        <div className="links-container">
          <a className="link" href="mailto:contact@developer.mwm.ai">
            Contact
          </a>
        </div>
      </div>
    </div>
  )
}

export default Home
